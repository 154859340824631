import {
  getInitialTradeDate,
  REQUEST_INTERVAL,
  subscriptionIntervals,
  TRADES_HISTORY_DEPT,
  TRADES_LIMIT
} from './trade-lab-common';
import { wsConnectorTradeGateway, wsTradeConnectors } from '../../components/system/websocket-component';
import { $$ } from '../../utils/utils';
import { WsConnector } from '../../components/ws-connector';
import { Trade } from './trade-types';


export const sendMessage = (ws: WsConnector, msg)=> {
      ws.send(msg)
  }

export const sendTradeRequest = (account_name: string,
                                 startTs: number | null = null)=> {
  const ws = getWsByAccountName(account_name);
  const message = {"msg":"TradeQuery",  "limit" : TRADES_LIMIT};

  if (!!startTs) message['startTs'] = startTs;
  // if (!!endTs)   message['endTs'] = endTs;
  sendMessage(ws, message);
}

export const getWsByAccountName = (account_name: string) =>  wsTradeConnectors[account_name];

export const doAction = (ws: WsConnector, action: string, params = undefined)=>  {
      const startTs = 1000 * $$.getDateTimestampMs($$.getYesterday());

      if (action == "PositionQuery") {
        sendMessage(ws, { "msg": "PositionQuery" });
      }
      if (action == "Symbols") {
        sendMessage(ws, { "msg": "Symbols", "pattern": "*" });
      }
      if (action == "KlineQuery") {
        sendMessage(ws, { "msg": "KlineQuery", "interval": 60, "symbol" : "ETHUSDT", "startTs": startTs });
      }
  }

export const initAccountSubscription = (account_name: string, getLastTrade: () => Trade | null) => {
  const checkIntervalName = `check-${account_name}`;
  const requestIntervalName = `reqst-${account_name}`;

  if (!subscriptionIntervals[requestIntervalName]) {
    const ws = getWsByAccountName(account_name);
    const lastTrade = getLastTrade ? getLastTrade() : undefined;
    const lastTs = lastTrade ? lastTrade.ts : getInitialTradeDate();

    if (ws) {
        doAction(ws, 'PositionQuery');
        sendTradeRequest(account_name, lastTs);

        subscriptionIntervals[requestIntervalName] = setInterval(() => {
          console.log("+++++++++++++++++++++++++++++++ INTERVAL [" + account_name + ']. lastTs::', lastTs)
          doAction(ws, 'PositionQuery');
          sendTradeRequest(account_name, lastTs);
        }, 1000 * REQUEST_INTERVAL);
      }
    }
    else {
      console.log("+++++++++++++++++++++++++++++++ Subscription already Inited. SKIPPED [" + account_name + ']')
    }
  }
