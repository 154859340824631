import { AccountInfo, Position } from './trade-types';
import React from 'react';
import { CaretRightOutlined, CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { $$ } from '../../utils/utils';
import { positionKey, sortedBy, Td, Ti } from './trade-lab-common';
import { Table } from '@mui/material';
import { CheckCircleFilled } from '@ant-design/icons/lib/icons';

// import {
//   UpOutlined,
//   CaretRightOutlined,
//   CheckCircleOutlined,
//   CaretDownOutlined,
//   SyncOutlined
// } from '@ant-design/icons';

interface TradePositionProps {
  positions: Position[];
  positionHistory: any;
  onAction: (a: any) => void;
  sortMode: {};
  expanded: number;
  positionOnlyNotZeroes: boolean;
  toggleNonZeroesSelect: () => void;
}

const PositionFieldNames = ['ts', 'symbol', 'account', 'position', 'initialMargin',
    'maintenanceMargin', 'positionInitialMargin', 'unrealizedPnL', 'leverage', 'entryPx'];

const detailValue = (position: Position, fieldName: string) => {
  const diff = $$.getDateDiffNow(position.ts / 1000);
  const howOld = $$.secondsToDHM(diff);
  const value = fieldName === 'ts' ? `${howOld} ago` : position[fieldName] || '';
  return <span>{value}</span>;
}

const detailName = (fieldName: string) =>  fieldName === 'ts' ? 'was updated' : fieldName;

const PositionDetail = ({position, expand }) => {
  return <table border={0} style={{width: '100%'}}><tbody>
    {PositionFieldNames.map((name, index) => <tr key={'position-detail' + name}>
    <Td>{detailName(name)}</Td><Td>{detailValue(position, name)}</Td></tr>)}
  </tbody></table>;
}

const TradePositionTable = (props: TradePositionProps) => {
  const { onAction, positions, sortMode, expanded, positionHistory, positionOnlyNotZeroes, toggleNonZeroesSelect } = props;
  
  const positionsView: Position[] = [];
  positions.filter(item => positionOnlyNotZeroes ? item.position !== '0.0' : true)
           .forEach((item, index) => {
    // const key = positionKey(item);
    const _deltas = positionHistory[positionKey(item)] || [];

    if (index === expanded) {
      positionsView.push({...item, _viewMode: 'marked', _deltas});
      positionsView.push({...item, _viewMode: 'expanded', _deltas});
    }
    else {
      positionsView.push({...item, _viewMode: 'normal', _deltas});
    }
  });

  // console.log("################# positionsView::",positionsView);

  const icon_form = () =>
    positionOnlyNotZeroes ? <CheckCircleFilled /> : <CheckCircleOutlined />;

  const applySortBy = (name: string) => {
    onAction({ mode: 'positions', name: name, value: !sortMode['value'] });
  }

  const fields = [
    {name:'instrumentCode', title: 'instrument'},
    {name:'account'},
    {name:'position'},
    {name:'0', sort: 'NO', title: '+-', render: (position: Position) => $$.roundFloat(position._deltas!["0"], 3)},
    // {name:'5', title: '5 min', render: (position: Position) => position._deltas!["5"]},
  ];

  const expand = (index: number) => {
    onAction({ mode: 'positions', name: '_expand_', value: index });
  }

  const getHeader = (field: any) => {
    const name = field.name;
    const title = field.title ? field.title === '_hidden_' ? '': field.title : field.name;
    const sortable = !(field.sort === 'NO');
    return <Td key={'ph-' + name}> {title}
      {sortable
        ? <span className={'preventSelect'} onClick={() => applySortBy(name)}>{sortedBy(name, sortMode)}</span>
        : null
      }
    </Td>};

  const getValue = (position: Position, field: any) => {
    return <Td key={`pos-${position.account}-${position.instrumentCode}-${field.name}`}>
      {field.render ? field.render(position) : position[field.name]}
    </Td>;
  }

  return <Table border={0} style={{ width: '100%' }}>
    <thead>
        <tr style={{backgroundColor: '#002553'}}>
          <td  colSpan={fields.length + 1}>
            &nbsp;&nbsp;&nbsp;POSITIONS&nbsp;&nbsp;&nbsp;
            <span  onClick={toggleNonZeroesSelect} style={{color: 'white'}}>{icon_form()}</span>
          </td></tr>
      <tr>
        <Ti/>
        {fields.map(field => getHeader(field))}
      </tr>
      </thead><tbody>

    { positionsView.map((position, row_index) =>
        <tr style={ row_index === expanded ? {backgroundColor: '#374860'}: {}}
            key={`pos-row-${position.account}-${position.instrumentCode}-${position._viewMode}}`}>

          {position._viewMode === 'normal' && <>
              <Ti><CaretRightOutlined onClick={() => expand(row_index)}/></Ti>
              {fields.map(field => getValue(position, field))}
          </>}
          {position._viewMode === 'marked' && <>
              <Ti><CaretRightOutlined onClick={() => expand(row_index)}/></Ti>
              {fields.map(field => getValue(position, field))}
          </>}
          {position._viewMode === 'expanded' && <>
              <Td colSpan={3}><PositionDetail position={position} expand={() => expand(row_index)}/></Td>
          </>}
        </tr>
      )}
  
    </tbody>
  </Table>;
}

export default TradePositionTable;
