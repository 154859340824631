import React from 'react';
import { connect } from 'react-redux';
import { RootState } from "store";

import {
  COMPONENT_KEY_NAME, getInitialTradeDate, initialTradesTracker,
  positionKey, sincTimeIntervals,
  stopIntervals,
  TRADES_LIMIT
} from './trade-lab-common';
import { wsConnectorTradeGateway } from 'components/system/websocket-component';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { tradeLabSliceActions } from './trade-lab.slice';
import AuthContext from 'contexts/DjangoAuthContext';
import { UserProfile } from '../../types/auth';

import { RouterParams } from '../../routes/router-proxy-model';
import { $$ } from '../../utils/utils';
import TradePositionTable from './trade-lab-positions';
import TradeBalanceTable from './trade-lab-balances';
import TradeTradesTable from './trade-lab-trades';
import { styled } from '@mui/material/styles';
import { getLocalStorageValue } from '../../utils/local_storage';
import { initTradeWs } from './trade-lab-wsconnector';
import TradeAccountTable from './trade-lab-accounts';
import {
  AccountInfo,
  AssetBalance,
  Position,
  Trade, TradeCommonMess,
  TraderInfo,
  TradesTracker
} from './trade-types';
import { initAccountSubscription, sendTradeRequest } from './trade-lab-actions';
import OrderActions from './trade-lab-order-actions';


const Td = styled('td')({
  width: '50%',
  verticalAlign: 'top',
  borderCollapse: 'collapse'
});

const Table = styled('table')({
  verticalAlign: 'top',
  paddingLeft: '5px',
  paddingRight: '5px',
  width: '100%',
});

interface ComponentProps extends RouterParams, StateProps, DispatchProps {}

class TradeLabComponent extends React.Component<ComponentProps> {
  static contextType = AuthContext;

  constructor(props) {
    super(props);``
  };

  user = this.props.user! as UserProfile;

  getAccountByName = (account_name: string): AccountInfo | null => {
    const accounts = this.props.accounts.filter(it => it.account_name === account_name);
    return accounts.length > 0 ? accounts[0]: null;
  }

  getLastTrade = (account_name: string): Trade => {
    const account = this.getAccountByName(account_name);
    if (account && account._tradesTracker) {
      return account._tradesTracker!.lastTrade as Trade
    }
    return { ts: getInitialTradeDate(), seqn: '-1' } as Trade;
  }


  initWs = () => {
    setTimeout(() => {
    console.log("################# USER", this.user, "this.user.account_info::", this.user.accounts_info);
      const appliedAccounts = getLocalStorageValue('_appliedAccounts') || [] as string[];
      if (!!this.user.accounts_info) {
        for (const account_into of this.user.accounts_info!) {
          const account_name = account_into['account_name'];
          if (appliedAccounts.includes(account_name)) {
            initTradeWs('logIn', this.user, account_into, this.props.updateInstantValues, () => this.getLastTrade(account_name));
            this.props.loadAccountApplying(appliedAccounts);
            setTimeout(() => initAccountSubscription(account_name, () => this.getLastTrade(account_name)), 500);
          }
        }
        this.props.loadAccountApplying(appliedAccounts);
      }
    }, 300);
  };

  componentDidUpdate(prev) {
    if (prev.responseCounterTrades !== this.props.responseCounterTrades) {
      // console.log('@@@@@@@@@@@@@@ componentDidUpdate:::::', prev.responseCounterTrades, this.props.responseCounterTrades)
      this.tradesCheckLoad();
    }
  }

  tradesCheckLoad = () => {
    // console.log('####################### tradesCheckLoad:: SKIPPED')
    for (const account of this.props.accounts.filter(it => it._viewMode === "applied")) {
      const account_name = account['account_name'];

      const traker = account._tradesTracker || initialTradesTracker() as TradesTracker;
      const lastPackSize = traker.lastPackSize;

      // console.log('############### lastPackSize::', lastPackSize)
      // console.log('############### TRADES_LIMIT::', TRADES_LIMIT)

      if (lastPackSize === TRADES_LIMIT) {
        const tradeTimes = Object.values(this.props.trades)
          .filter(it => it.account_name === account_name)
          .map((trade: Trade) => trade.ts);

         const newestTradeTime = Math.max(...tradeTimes);
         // const newestTradeTime = this.getLastTrade().ts;
         sendTradeRequest(account_name, newestTradeTime);
        }
    }
  }

  loginToAccount = (mode: 'logIn' | 'logOut', user: UserProfile, account: AccountInfo)=> {
    const error = initTradeWs(mode, user, account, this.props.updateInstantValues, () => this.getLastTrade(account.account_name));
    this.props.updateAccountStatus({account, mode, error, loading: false});
  }

  doSortAction = (action: any) => this.props.applyAction(action)
  toggleAccountsShowList = () => this.props.toggleAccountsShowList('');
  toggleOrdersShowList = () => this.props.toggleOrdersShowList('');
  toggleNonZeroesSelect = () => this.props.toggleNonZeroesSelect('');

  toggleAccountSelect = (account: AccountInfo, force_mode: any = null) => {
    const currentViewMode = account._viewMode;
    const mode = force_mode || currentViewMode === 'not_applied' ? 'logIn' : 'logOut';

    this.loginToAccount(mode, this.user, account);

    if (mode === 'logIn') {
      setTimeout(() => initAccountSubscription(account.account_name, () => this.getLastTrade(account.account_name)), 1500);
    }

    if (mode === 'logOut' || account._error) {
      stopIntervals(account.account_name);
    }

    this.props.toggleAccount(account);
  }

  componentDidMount() {
      this.initWs();
      this.props.setAccounts(this.user.accounts_info)

      if (!sincTimeIntervals['_sinc_']) {
        sincTimeIntervals['_sinc_'] = setInterval(() => this.props.sincTimeUpdate({}), 1000);
      }
      // @todo - not required - should be removed
      // const positions = getCachedValue('_positions') as {};
      // if (positions) {
      //   this.props.updateInstantValues({...positions, 'msg': '_PositionRestoreAfterRefresh_'});
      // }
    }


  render() {
    const {
      sincTime,
      traderIds,
      accounts,
      accountsShowList,

      balanceList,
      balanceMode,
      balanceExpanded,
      balanceHistory,

      positionList,
      positionMode,
      positionExpanded,
      positionHistory,
      positionOnlyNotZeroes,

      tradeList,
      // lastTrade,
      tradeMode,

      lastOrderStatus,
      lastOrderTrade,
      ordersShowList
    } = this.props;

    if (!this.user || !this.user.trader_info) {
      return <div />;
    }
    // const traderIdsSorted = [...traderIds].sort((a, b) => a - b);
    // traderIds.sort((a, b) => a - b);

    const traderInfo = this.user!.trader_info! as TraderInfo;

    const accountNames = accounts.map(account => account._viewMode === 'applied' ? account.account_name: null).filter(it => !!it);

    const balanceActualList  = balanceList.map(
      balance => accountNames.includes(balance.account) ? balance : null).filter(it => !!it) as AssetBalance [];

    const positionActualList = positionList.map(
      position => accountNames.includes(position.account) ? position : null).filter(it => !!it) as Position [];

    const tradeActualList = tradeList.map(
      trade => accountNames.includes(trade.account_name!) ? trade : null).filter(it => !!it) as Trade [];


    const errors = accounts.map((account: AccountInfo) => account._error).filter(it => !!it);
    const errorMessage = errors.join('; ');

    // console.log('################ positionActualList ', positionActualList);
    // console.log('################ balanceActualList ', balanceActualList);
    // console.log('################ accounts ', accounts);

    return <div style={{color: 'white'}}>
        <div className={'titleGraphs'}>
          &nbsp;{traderInfo.trader_id}
          &nbsp;{traderInfo.trader_name}
          {/*&nbsp;&nbsp;&nbsp;&nbsp;{`[${visibleAccountsStr}]`}*/}
        </div><br/>
          {/*&nbsp;&nbsp;<button className={'advancedBtn'}  onClick={() => this.doAction('TradeQuery')}>TradeQuery</button>*/}
          {/*&nbsp;&nbsp;<button className={'advancedBtn'}  onClick={() => this.doAction(wsConnectorTradeGateway, 'MetaData')}>MetaData</button>**/}
          {/*/!*&nbsp;&nbsp;<button className={'advancedBtn'}  onClick={() => this.doAction('Symbols')}>Symbols</button>*!/*/}
          {/*/!*&nbsp;&nbsp;<button className={'advancedBtn'}  onClick={() => this.doAction('KlineQuery')}>Kline</button>*!/*/}
          {/*&nbsp;&nbsp;<button className={'advancedBtn'}  onClick={() => this.doAction('PositionQuery')}>PositionQuery</button>*/}
          {/*&nbsp;&nbsp;<button className={'advancedBtn'}  onClick={() => this.testPosition('A')}>PositionQueryXTZ</button>*/}
          {/*&nbsp;&nbsp;<button className={'advancedBtn'}  onClick={() => this.testPosition('B')}>PositionQueryETH</button>*/}


      <Table>
        <tbody>
          <tr>
          <Td style={{ borderBottom: '2px solid #002553' }} colSpan={2}>
            <TradeAccountTable showList={accountsShowList}
                               toggleShowList={this.toggleAccountsShowList}
                               toggleSelect={(account) => this.toggleAccountSelect(account, null)}
                               accounts={accounts}
                               sincTime={sincTime}
                               errorMessage={errorMessage}
                               trades={tradeActualList}/>
          </Td>
        </tr>
          <tr><Td colSpan={2}>
            <OrderActions accounts={accounts}
                    showList={ordersShowList}
                    toggleShowList={this.toggleOrdersShowList}
                    lastOrderStatus={lastOrderStatus}
                    lastOrderTrade={lastOrderTrade}
            />
          </Td></tr>
          <tr><td colSpan={2}></td></tr>
        <tr>
          <Td style={{ borderRight: '2px solid #002553',  borderTop: '2px solid #002553'}}>
            <TradePositionTable onAction={this.doSortAction}
                                positionOnlyNotZeroes={positionOnlyNotZeroes}
                                sortMode={positionMode}
                                expanded={positionExpanded}
                                toggleNonZeroesSelect={this.toggleNonZeroesSelect}
                                positions={positionActualList}
                                positionHistory={positionHistory}/></Td>
          <Td style={{ borderTop: '2px solid #002553'}}>
             <TradeBalanceTable onAction={this.doSortAction}
                                sortMode={balanceMode}
                                expanded={balanceExpanded}
                                balances={balanceActualList}
                                balanceHistory={balanceHistory}/></Td>
        </tr>
        <tr>
          <Td style={{ borderBottom: '2px solid #002553' }} colSpan={2}>
            <TradeTradesTable onAction={this.doSortAction}
                              sortMode={tradeMode}
                              traderIds={traderIds}
                              trades={tradeActualList}/>
          </Td>
        </tr>
        </tbody>
      </Table>
     </div>;
  }
}

const ownSettings = {
    version: '0.02'
};


const mapStateToProps = ( { [COMPONENT_KEY_NAME]: state } : RootState) => ({
  ...ownSettings, ...state
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
      updateInstantValues:bindActionCreators(tradeLabSliceActions.updateInstantValues, dispatch),
      applyAction:bindActionCreators(tradeLabSliceActions.applyAction, dispatch),
      setAccounts:bindActionCreators(tradeLabSliceActions.setAccounts, dispatch),
      toggleAccount:bindActionCreators(tradeLabSliceActions.toggleAccount, dispatch),
      toggleAccountsShowList:bindActionCreators(tradeLabSliceActions.toggleAccountsShowList, dispatch),
      toggleOrdersShowList:bindActionCreators(tradeLabSliceActions.toggleOrdersShowList, dispatch),
      toggleNonZeroesSelect:bindActionCreators(tradeLabSliceActions.toggleNonZeroesSelect, dispatch),
      loadAccountApplying:bindActionCreators(tradeLabSliceActions.loadAccountApplying, dispatch),
      updateAccountStatus:bindActionCreators(tradeLabSliceActions.updateAccountStatus, dispatch),
      diffCalculate:bindActionCreators(tradeLabSliceActions.diffCalculate, dispatch),
      sincTimeUpdate:bindActionCreators(tradeLabSliceActions.sincTimeUpdate, dispatch),
});


type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export default connect(mapStateToProps, mapDispatchToProps)(TradeLabComponent);
