import { useLocation, useNavigate, useParams } from "react-router-dom";

import TradeLab from './trade-lab';
import useAuth from '../../hooks/useAuth';
import { getCachedValue } from '../../utils/local_storage';
import React from 'react';

export function routerTradeLabInjector() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const { user, need_login_as_trader, isLoggedInAsTrader } = useAuth();

    const traderLogInfo = getCachedValue('_user');

    if (!isLoggedInAsTrader) {
      setTimeout(() =>  need_login_as_trader(), 200);
      return <></>;
    }

    return <TradeLab
      user = {{...traderLogInfo, ...user}} as UserProfile
      router={{ location, navigate, params }}/>;
}
export default routerTradeLabInjector;
