
import { AccountInfo, AssetBalance, Position, Trade } from './trade-types';
import React, { useEffect } from 'react';
import { $$ } from '../../utils/utils';
import { styled } from '@mui/material/styles';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CaretRightOutlined,
  CheckCircleOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { CheckCircleFilled } from '@ant-design/icons/lib/icons';


const Td = styled('td')({
  verticalAlign: 'top',
  paddingLeft: '5px',
  paddingRight: '5px',
  whiteSpace: 'nowrap'
});


interface TradeTradesProps {
  trades: Trade[];
  onAction: (a: any) => void;
  sortMode: {};
  traderIds: number[];
}

const TradeTradesTable = (props: TradeTradesProps) => {
  const { onAction, trades, sortMode, traderIds } = props;

  const sortedBy = (field: string) => {
    return field !== sortMode['name']
      ? <CaretUpOutlined style={{color: 'grey'}} className={'tableArrowUp'} />
      : sortMode['value']
          ? <CaretUpOutlined   style={{color: 'red'}} className={'tableArrowUp'} />
          : <CaretDownOutlined style={{color: 'red'}} className={'tableArrowDown'} />};

  const applySortBy = (name: string) =>
    onAction({mode: 'trades', name: name, value: !sortMode['value'] });


  const toggleTraderId = (traderId: number) =>
    onAction({mode: 'trades.traderId', name: '', value: traderId});


  const fields = [
    {name:'ts', title: 'date', render: (trade) => $$.getDateTimeAsSimpleStringFromMs(trade.ts / 1000)},
    {name:'symbol'},
    {name:'tradePrice', title: 'price'},
    {name:'account_name', title: 'account'},
    {name:'traderId', title: 'traderId'},
    {name:'side'},
    {name:'status'},
    {name:'maker'},
    {name:'execQty'},
    {name:'commission'},
    {name:'commissionAsset', title: ' '},
    {name:'clientOrderId'},
    {name:'exchangeOrderId'},
    {name:'tradeId'}
  ];

  const trader_id_icon = (traderId: any) =>
      traderIds.includes(traderId) ? <CheckCircleFilled /> : <CheckCircleOutlined />

  const tradesCount = (traderId) => trades.filter(t => t.traderId === traderId).length;

  const clientsStaff = () => {
    const its = trades.map((trade: Trade) => trade.traderId);
    const ii = new Set(its).values();

    return [...ii].map(id => <span key={id} onClick={() => toggleTraderId(id)}>{trader_id_icon(id)}&nbsp;{id}&nbsp;[{tradesCount(id)}]&nbsp;&nbsp;</span>);
  };

  const getHeader = (field: any) => {
    const name = field.name;
    const title = field.title || field.name;
    return <Td key={field.name}>{title} <span className={'preventSelect'} onClick={() => applySortBy(name)}>{sortedBy(name)}</span></Td>};

  const getValue = (trade: Trade, field: any) =>
    <Td key={field.name}>{ field.render ? field.render(trade) : trade[field.name]} </Td>;

  return <table style={{ width: '100%' }}>
    <thead>
    <tr key={'orders-Header'} style={{backgroundColor: '#002553'}}>
      <td  colSpan={fields.length}>&nbsp;&nbsp;&nbsp;TRADES&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{clientsStaff()}</td></tr>
    <tr>
      {fields.map((field: any, index: number) => getHeader(field))}
    </tr>
    </thead><tbody>

  {trades.filter(t => traderIds.includes(t.traderId)).map((item, index) => {
    const trade = item as Trade;
    return <tr key={trade.tradeId + trade.traderId}>
        {fields.map((field: any, index: number) => getValue(trade, field))}
        </tr>})}
    </tbody>
  </table>
}

export default TradeTradesTable;
