import { wsTradeConnectors } from '../../components/system/websocket-component';
import { AccountInfo, Trade } from './trade-types';
import { WsTradeConnector } from '../../components/ws-connector';
import { UserProfile } from '../../types/auth';
import { initAccountSubscription } from './trade-lab-actions';
import { stopIntervals } from './trade-lab-common';

const exchangesTradeWss = {
  // 'BNF': 'ws://3.78.53.123:8099',
  // 'BNF': 'ws://3.78.53.123:8099',   // Beta
  // 'BNF': 'ws://3.78.53.123:8097',   // Prod
  // 'BNF': 'ws://orders.newbridge.ai:8099', //Beta
  // 'BNF': 'ws://3.78.53.123:8097',
  // 'BNS': 'ws://3.78.53.123:8088',
  // 'BNS': 'ws://platform.newbridge.ai/trd-1050203',
}

export const halt_connection = (account_name: string)=> {
  const WS = wsTradeConnectors[account_name];
  if(!!WS) {
      WS.disconnect();
      WS.halt();
      delete wsTradeConnectors[account_name];
  }
}

export const initTradeWs = (
  mode: 'logIn' | 'logOut',
  user: UserProfile,
  account: AccountInfo,
  performer: (data: Object) => void,
  getLastTrade: () => Trade)=>
{
    // console.log("============ ACCOUNT", account);

    const account_name  = account.account_name;
    const exchange_name = account.exchange_name;
    let exchange_egw  = account.egw_address;

    let WS = wsTradeConnectors[account_name];

    if (! WS) {
      if (mode == 'logOut') {
        console.warn('############### ATTENTION. Websocket not initialised.', account_name);
        return null;
      }
      // const url = exchangesTradeWss[exchange_name];
      console.log('################### exchange_egw:: [', exchange_egw, '] #################')
      console.log('################### exchange_egw:: [', exchange_egw, '] #################')
      console.log('################### exchange_egw:: [', exchange_egw, '] #################')

      if (exchange_egw === 'ws://3.78.53.123:8076') {
        exchange_egw = 'wss://platform.newbridge.ai/trd/8076';
      }
      // exchange_egw = exchange_egw.replace('ws://3.78.53.123:', 'wss://platform.newbridge.ai/trd/')
      console.log('################### exchange_egw:: [', exchange_egw, '] ################*')
      console.log('################### exchange_egw:: [', exchange_egw, '] ################*')
      console.log('################### exchange_egw:: [', exchange_egw, '] ################*')
      const url = exchange_egw;
      if (!url) {
        console.warn("############### ATTENTION. Please define URL for '" + exchange_name + "'");
        return "not configured"
      }

      const on_message =
        payload => performer({ ...JSON.parse(payload.data || '{}'), account_name: account_name });

      const on_error = (ws) => {
        performer({msg: '_ConnectionError_', errMessage: 'Disconnected. Please wait. Should be restored automatically', account_name });
      }

      const on_connect = (ws) => {
        // console.log("######################### on_connect - LOG IN ############################");
        ws.send({
          "msg": "LogonRequest",
          "token": user.password,
          "account": account_name,
          "traderId": user.name,
        });
        initAccountSubscription(account_name, getLastTrade);
      }

      WS = new WsTradeConnector(url, account_name, on_connect, on_message, on_error);
      WS.connect();

      wsTradeConnectors[account_name] = WS;
      // console.log("************************** CONNECTED [" + account_name + "] **************************")
    }

    if (mode == 'logIn') {
      /* do nothing here - AUTO LOGIN will be applied */
      // initAccountSubscription(account_name);
    }
    if (mode == 'logOut') {
        // console.log("************************** LOG OUT **************************")
        /* It is not required logoff for this kind of WS - just need disconnect */
        halt_connection(account_name)
        stopIntervals(account.account_name);
        performer({'msg': 'LogoffReply', account_name});
    }
    return null;
}
