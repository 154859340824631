import { styled } from '@mui/material/styles';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import React from 'react';
import { AccountInfo, AssetBalance, Position } from './trade-types';
import { initTradeWs } from './trade-lab-wsconnector';
import { getCachedValue } from '../../utils/local_storage';
import { $$ } from '../../utils/utils';

export const COMPONENT_KEY_NAME = 'tradeLab';

export const Table = styled('table')({
  width: '100%',
  borderCollapse: 'collapse'
})

export const Td = styled('td')({
  verticalAlign: 'top',
  paddingLeft: '15px',
  paddingRight: '5px',
  whiteSpace: 'nowrap'
});

export const Ti = styled('td')({
  verticalAlign: 'top',
  width: '0px',
  minWidth: '0px',
  maxWidth: '0px',
});

export const Ta = styled('td')({
  verticalAlign: 'top',
  width: '10px',
  minWidth: '10px',
  maxWidth: '10px',
});

export const sortedBy = (field: string, sortMode: any) => {
    return field !== sortMode['name']
      ? <CaretUpOutlined style={{color: 'grey'}} className={'tableArrowUp'} />
      : sortMode['value']
          ? <CaretUpOutlined   style={{color: 'red'}} className={'tableArrowUp'} />
          : <CaretDownOutlined style={{color: 'red'}} className={'tableArrowDown'} />};

// https://ant.design/components/icon

export const balanceKey = (balance: AssetBalance) => `${balance.account}:${balance.asset}`;
export const positionKey = (position: Position) => `${position.account}:${position.instrumentCode}`;


// inject_deltas(actualList: any[], positionHistory: any, keyFunction: (any) => any) {
//   for (const item of actualList) {
//     const key = keyFunction(item);
//     const historyItem = positionHistory[key];
//     if (historyItem) {
//       for (const d of Object.keys(historyItem)) {
//         // if (!item._deltas) {
//         //   item._deltas = {}
//         // }
//         item._deltas[d] = historyItem[d];
//       }
//     }
//   }
//   return actualList;
// }

export const get_deltas = (item: Position | AssetBalance)=> {
  return [0,1];
}


export const TRADES_LIMIT = 100;
export const TRADES_HISTORY_DEPT = 7 * 24 * 60 * 60 * 1000 * 1000; // milliseconds
export const REQUEST_INTERVAL = 60;
export const sincTimeIntervals = {};
export const subscriptionIntervals = {};
export const subscriptionTimeouts = {};

export const getInitialTradeDate = () =>
  $$.getDateTimestampMs() * 1000 - TRADES_HISTORY_DEPT;

export const initialTradesTracker = () => ({
  responseCounter: 0,
  lastPackSize: 0,
  lastTrade: { ts: getInitialTradeDate(), seqn: '-1' },
  lastTradeTs: 0,
  });


export const stopIntervals = (account_name: string) => {
  const intervalNames = [
    `check-${account_name}`,
    `reqst-${account_name}`];

  for(const intervalName of intervalNames) {
    const interval = subscriptionIntervals[intervalName];
    if (interval) {
      clearInterval(interval);
      delete subscriptionIntervals[intervalName];
      // console.log('----------INTERVAL STOPPED -------- [' + intervalName + '] -----------');
    }
  }
  for(const intervalName of intervalNames) {
    const timeout = subscriptionIntervals[intervalName];
    if (timeout) {
        clearTimeout(timeout);
        delete subscriptionTimeouts[intervalName];
        // console.log('----------TIMEOUT STOPPED -------- [' + intervalName + '] -----------');
    }
  }
};

export const startCheckInterval = (account: AccountInfo, processor, getLastTrade) => {
  const user = getCachedValue('_user');
  console.log('############## startCheck ' + account.account_name +' ###################')
   initTradeWs('logIn', user, account, processor, getLastTrade);
}
