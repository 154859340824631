import { AccountInfo, AssetBalance, ORDER_TYPE, OrderReply, OrderTrade, Trade } from './trade-types';
import React, { useEffect, useRef, useState } from 'react';


import { REQUEST_INTERVAL, sortedBy, Ta, Td, Ti } from './trade-lab-common';
import { $$ } from 'utils/utils';
import { MyTextField } from '../../components/controls/form/textfield';
import { RefHoster } from '../../components/controls/form/ref-hoster';
import { doAction, getWsByAccountName, sendMessage, sendTradeRequest } from './trade-lab-actions';
import { mainBackgroundColor, primaryTextColor } from '../../config';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Table } from '@mui/material';

interface OrderActionsProps {
    accounts: AccountInfo[],
    lastOrderStatus: OrderReply,
    lastOrderTrade: OrderTrade,
    showList: boolean,
    toggleShowList: () => void
}

const OrderActions = (props: OrderActionsProps) => {
  const { accounts, lastOrderStatus, lastOrderTrade, showList, toggleShowList } = props;

  const [errorMessage, setErrorMessage] = useState(lastOrderStatus && lastOrderStatus.errCode !== 0 ? lastOrderStatus.errMessage : '');
  const [errorMessageType, setErrorMessageType] = useState('undef')

  const accountsApplied = accounts.filter(account =>  account._viewMode === 'applied');
  const testAccounts = accountsApplied.filter(account => account.account_name.startsWith('TST'));
  const accountSelectedCount = accountsApplied.length;
  const account = accountSelectedCount === 0 ? {account_name: 'XXX'}  as AccountInfo : accountsApplied[0] as AccountInfo;
  const isTestAccount = account.account_name.startsWith('TS');
  const isRealAccountPossible = true;

  const possibleAccount = isRealAccountPossible || isTestAccount;

  const symbolName = 'symbol';
  const qtyName = 'qty';
  const defaultSymbolName = 'BTCUSDT';
  const valuesRefs = new RefHoster();
  const getInputValue = (id: string, defaultValue:any = '') => valuesRefs.getInputValue(id, defaultValue);

  const defaultQty = '0.001';

  const orderValue = (order: OrderTrade) => $$.roundFloat(parseFloat(order.tradePrice) * parseFloat(order.execQty), 4);
  const composeCompleteOrderMess = (order: OrderTrade) =>
    order.side
      ? `${order.side} :: ${order.execQty} ${order.symbol} tradePrice: ${order.tradePrice} commission: ${order.commission} ${order.commissionAsset} (volume: ${orderValue(order)})`
      : '';

  const getMessageColor = () => errorMessageType === 'ERROR'? 'red' : 'white';

  useEffect(() => {
    const errMsg = lastOrderStatus ? lastOrderStatus.errMessage: '';
    setErrorMessageType(errMsg? 'ERROR' : 'OK');
    setErrorMessage(errMsg)
  }, [lastOrderStatus]);

  useEffect(() => {
    setErrorMessage(lastOrderTrade ? composeCompleteOrderMess(lastOrderTrade) : '')
    setErrorMessageType('OK');
  }, [lastOrderTrade]);


  const applyOrderAction = (action: string, params: any  ) => {
     const ws = getWsByAccountName(account.account_name);
     if (ws) {
        sendMessage(ws, { "msg": action, ...params });
        setTimeout(() => sendMessage(ws, { "msg": "PositionQuery"}), 900);
        setTimeout(() => sendTradeRequest(account.account_name), 1000);
        setErrorMessageType("OK");
        setErrorMessage('Order sent');
     }
     else {
       setErrorMessage('ERROR. Cannot place an Order');
     }
  }

  const composeOrder = (side: 'BUY' | "SELL") => {
      // https://apidocs.newbridge.ai/#strong-order-management-api-strong-logon-reply
      const symbol = getInputValue(symbolName)
      const qty = getInputValue(qtyName)
      const clientOrderId = 1000 * $$.getDateTimestampMs();
      const orderType = 'MARKET' as ORDER_TYPE;
      return {clientOrderId, side, totalQty: qty, symbol, type: orderType, tif : 'GTC'};
  }

  const okClose = () => setErrorMessage('')

  const buy = () => {
    applyOrderAction('NewOrder', composeOrder('BUY'));
  }
  const save = () => {
     applyOrderAction('NewOrder', composeOrder('SELL'));
  }
  return <Table key={'account-main'} border={0} style={{ width: '100%' }}>
    <thead>
    <tr style={{backgroundColor: '#002553'}}>
      <td>
        <span onClick={toggleShowList}>{ showList ? <CaretDownOutlined/> : <CaretRightOutlined/>}</span>
        &nbsp;&nbsp;ORDERS
        {/*&nbsp;&nbsp;&nbsp;&nbsp;<span style={{color: 'red'}}>{errorMessage}</span>*/}
      </td>
    </tr>
    {!showList ? null :
    <tr><td>
      { accountSelectedCount === 0
      ? <div><span style={{color: 'white'}}>No accounts selected for Ordering.</span></div>
      : accountSelectedCount > 1
      ? <div><span style={{color: 'red'}}>Please select only a single account for Ordering. Selected [{accountSelectedCount}]</span></div>
        : ! possibleAccount ? <div>Only a TEST accounts are supported for Orders now</div>
          : <div>
          <h3>Account: {account.account_name} [{account.account_id}]</h3>
              {! isTestAccount && <span style={{color: 'red'}}>ATTENTION - THIS IS A REAL ACCOUNT</span>}
          <p/>
          <table><tbody>
            <tr><Td>Symbol</Td><Td><MyTextField inputRef={el => valuesRefs.initRef(symbolName, el)} defaultValue={defaultSymbolName}/></Td><Td>&nbsp;</Td></tr>
            <tr><Td>Qty</Td><Td><MyTextField inputRef={el => valuesRefs.initRef(qtyName, el)} defaultValue={defaultQty}/></Td><Td>&nbsp;</Td></tr>

          { errorMessage
            ? <tr><Td colSpan={2}><span style={{color: getMessageColor()}}>{errorMessage}</span></Td>
            <Td><button className={'advancedBtn'} onClick={okClose} style={{color:primaryTextColor,
                border:'1px solid '+primaryTextColor, backgroundColor:mainBackgroundColor }}>
                &nbsp;&nbsp; OK &nbsp;&nbsp;
              </button>
            </Td>

          </tr>
            : <tr>
              <Td colSpan={2}>&nbsp;</Td>
              <Td>
                 <button className={'advancedBtn'} onClick={save}>&nbsp;&nbsp; Sell &nbsp;&nbsp;</button>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                 <button className={'advancedBtn'} onClick={buy}>&nbsp;&nbsp; Buy &nbsp;&nbsp;</button>
              </Td>
            </tr>
          }
          </tbody></table>
        </div>
      }
      </td></tr>
    }
    </thead>
    </Table>
}

export default OrderActions;
